<template>
<div class="table-add" v-loading.fullscreen.lock="loading">
    <div class="table-add-header">
        <h1>Редагувати  Переваги</h1>
        <div class="flex mt-20">
            <button class="table-lang-button" type="button" @click="langName='ua'" :class="langName=='ua'?'active':''">UA</button>
            <button class="table-lang-button ml-10" type="button" @click="langName='en'" :class="langName=='en'?'active':''">ENG</button>
        </div>
        <div class="add-inputs" style="max-width:1000px;">
            <el-form ref="pagesForm" :model="Pages" :rules="PagesRules">
                <div class="form-au" v-show="langName=='ua'">
                    <div class="item mt-20">
                        <p> Name</p>
                        <el-form-item prop="data.name_ua">
                            <el-input v-model="Pages.data.name_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p> Name description</p>
                        <el-form-item prop="data.name_ua">
                            <el-input v-model="Pages.data.link_dec_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p> Title</p>
                        <el-form-item prop="data.title_ua">
                            <el-input v-model="Pages.data.title_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Description</p>
                        <el-form-item prop="data.description_ua">
                            <el-input type="textarea" :rows="4" v-model="Pages.data.description_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item prop="files.Image">
                            <div class="upload-image">
                                <button class="bg-red">
                                    Image Upload
                                    <input type="file" name="image" @change="UploadIMage($event ,'Image')" accept="image/*">
                                </button>
                                <p :class="erros.icon?'c-red':''"> {{Pages.files.Image&&Pages.files.Image.name? Pages.files.Image.name: Pages.files.Image ? Pages.files.Image :'Upload Image'}} </p>
                            </div>
                            <p v-if="erros.icon" class="error-text">Upload image</p>
                        </el-form-item>
                    </div>
                    <h2 class="admin-h2-title">Templates</h2>
                    <div class="item flex items-center fw-w">
                        <el-form-item class="mr-20">
                            <el-checkbox size="medium" v-model="Pages.data.linkes" class="mt-20">Linkes</el-checkbox>
                        </el-form-item>
                        <el-form-item class="mr-20">
                            <el-checkbox size="medium" v-model="Pages.data.credit" class="mt-20"> Credit</el-checkbox>
                        </el-form-item>
                        <el-form-item class="mr-20">
                            <el-checkbox size="medium" v-model="Pages.data.contact" class="mt-20">Contact</el-checkbox>
                        </el-form-item>
                    </div>
                    <h1>About Blogs</h1>
                    <div v-for="(item, i) in Pages.data.about" :key="i">
                        <div class="item mt-20">
                            <p> Title</p>
                            <el-form-item prop="data.about">
                                <el-input v-model="item.title1_ua"></el-input>
                            </el-form-item>
                        </div>
                        <div class="item mt-20">
                            <p> Description</p>
                            <el-form-item prop="data.about">
                                <el-input v-model="item.title2_ua"></el-input>
                            </el-form-item>
                        </div>
                        <div class="item">
                            <p>Text</p>
                            <el-form-item prop="data.about">
                                <el-input type="textarea" :rows="4" v-model="item.text_ua"></el-input>
                            </el-form-item>
                        </div>
                        <div class="item">
                            <el-form-item prop="files.Image">
                                <div class="upload-image">
                                    <button class="bg-red">
                                        Image Upload
                                        <input type="file" name="image" @change="UploadIMage($event , `aboutImage${item.id}`)" accept="image/*">
                                    </button>
                                    <p :class="erros.icon?'c-red':''"> {{Pages.files[`aboutImage${item.id}`]&&Pages.files[`aboutImage${item.id}`].name? Pages.files[`aboutImage${item.id}`].name: Pages.files[`aboutImage${item.id}`] ? Pages.files[`aboutImage${item.id}`] :'Upload Image'}} </p>
                                </div>
                                <p v-if="erros.icon" class="error-text">Upload image</p>
                            </el-form-item>
                        </div>
                        <div style="padding-bottom:20px;" class="flex justify-end">
                            <button class="button red" style="background:red;" @click="deleteBlock(i)">Delete</button>
                        </div>
                    </div> 
                    <button type="button" @click="PushBlock" class="button">Add block</button>
                </div>
                <div class="form-en mt-20" v-show="langName=='en'">
                   
                    <div class="item mt-20">
                        <p> Name</p>
                        <el-form-item prop="data.name_ua">
                            <el-input v-model="Pages.data.name_en"></el-input>
                        </el-form-item>
                    </div>

                    <div class="item mt-20">
                        <p> Name description</p>
                        <el-form-item prop="data.name_ua">
                            <el-input v-model="Pages.data.link_dec_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p> Title</p>
                        <el-form-item prop="data.title_en">
                            <el-input v-model="Pages.data.title_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Description</p>
                        <el-form-item prop="data.description_en">
                            <el-input type="textarea" :rows="4" v-model="Pages.data.description_en"></el-input>
                        </el-form-item>
                    </div>
                    <div v-for="(item, i) in Pages.data.about" :key="i">
                        <div class="item mt-20">
                            <p> Title</p>
                            <el-form-item prop="data.about">
                                <el-input v-model="item.title1_en"></el-input>
                            </el-form-item>
                        </div>
                        <div class="item mt-20">
                            <p> Description</p>
                            <el-form-item prop="data.about">
                                <el-input v-model="item.title2_en"></el-input>
                            </el-form-item>
                        </div>
                        <div class="item">
                            <p>Text</p>
                            <el-form-item prop="data.about">
                                <el-input type="textarea" :rows="4" v-model="item.text_en"></el-input>
                            </el-form-item>
                        </div>
                        <div style="padding-bottom:20px;" class="flex justify-end">
                            <button class="button red" style="background:red;" @click="deleteBlock(i)">Delete</button>
                        </div>
                    </div>
                </div>
                    <div class="save-buttons">
                        <button class="button" type="button" @click="$router.push('/admin/statpages')"> {{$t('back')}} </button>
                        <button class="button save ml-10" type="button" @click="PostStock()"> Edit</button>
                    </div>
            </el-form>
        </div> 
    </div>

</div>
</template>

<script>
export default {
    data() {
        return {
            checked: false,
            langName: 'ua',
            fileList: [],
            value1: '',
            form: {
                name: ''
            },
            loading: false,
            erros: {
                image: false,
                en: false,
                ua: false
            },
            Pages: {
                key: "/advantages",
                data: {
                    name_ua: '',
                    name_en: '',
                    link_dec_ua: '',
                    link_dec_en: '',
                    title_ua: '',
                    title_en: '',
                    description_ua: '',
                    description_en: '',
                    linkes: false,
                    credit: false,
                    contact: false,
                    about: [{
                        id:1,
                        title1_ua: '',
                        title1_en: '',
                        title2_ua: '',
                        title2_en: '',
                        text_ua: '',
                        text_en: '', 
                    }]

                },
                files: {
                    Image: '',
                    aboutImage1: ''
                }
            },
            PagesRules: {
                data: {
                    name_ua: [{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    name_en: [{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    link_dec_ua: [{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    link_dec_en: [{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    title_ua: [{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    title_en: [{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    description_ua: [{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    description_en: [{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],

                },
                files: {
                     
                }

            },
        }

    },

    methods: {
        UploadIMage(event, name) {
            console.log(name) 
            this.Pages.files={...this.Pages.files,[name]:event.target.files[0]}
        },
        PostStock() {

            this.$refs.pagesForm.validate((valid) => {
                if (valid) {
                    this.loading = true
                    let files = new FormData();
                    var pageData = {}
                    if (typeof this.Pages.data != 'string') {
                        pageData = JSON.stringify(this.Pages.data)
                    }
                    files.append('key', this.Pages.key)
                    files.append('data', pageData)
                    for (let i of Object.keys(this.Pages.files)) {
                        if (this.Pages.files[i].name) {
                            files.append(`files[${i}]`, this.Pages.files[i]);
                        }
                    }

                    this.axios.post(this.Pages.id ? `/admin/statpage/${this.Pages.id}` : '/admin/statpage', files).then(res => {
                            console.log(res)
                            this.$router.push('/admin/statpages')
                            this.$notify({
                                title: 'Успешный',
                                message: 'Информация успешно сохранена',
                                type: 'success'
                            });
                            this.$store.dispatch("get_statpages");
                        })
                        .catch(error => {
                            this.erorrsData = error.response.data.message
                            let ERRORS = error.response.data.errors;
                            let keys = Object.keys(ERRORS);
                            let error_msg = ERRORS[keys[0]];
                            console.log(error_msg[0])
                            this.$notify.error({
                                title: 'Произошла ошибка',
                                message: error_msg ? error_msg[0] : "Произошла ошибка",
                            });

                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    console.log("AAA")
                }
            })

        },
        GetPages() {
            this.loading = true
            this.axios.get('/admin/statpage')
                .then(res => {
                    var data = res.data.data
                    var home = data.filter(a => {
                        return a.key === '/advantages'
                    })
                    if (home[0]) {
                        this.Pages.id = home[0].id
                        this.Pages.key = home[0].key
                        this.Pages.data = home[0].data
                        for (let i of Object.keys(home[0].files)) {
                            this.Pages.files[i] = home[0].files[i]
                        }

                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        PushBlock(){
            var data={
                        id:1,
                        title1_ua: '',
                        title1_en: '',
                        title2_ua: '',
                        title2_en: '',
                        text_ua: '',
                        text_en: '',
                    }
            var max=1;
            for(let i=0; i<this.Pages.data.about.length; i++){
                if(this.Pages.data.about[i].id>max || this.Pages.data.about[i].id==max){
                    max=this.Pages.data.about[i].id
                }
            }
            
            data.id=max+1
            this.Pages.data.about.push(data)
        },
        deleteBlock(i){
            var key='aboutImage'+this.Pages.data.about[i].id
            delete this.Pages.files[key]
            this.Pages.data.about.splice(i,1)
        }

    },
    mounted() {
        this.GetPages()
    }
}
</script>

<style lang="scss" scoped>
.date-line {
    width: 30px;
    height: 3px;
    background: rgba(69, 72, 81, 0.8);
    border-radius: 5px;
    margin: 0px 10px;
}

.save-buttons {
    padding-top: 20px;
}

.upload-image {
    width: 100%;
    display: flex;
    align-items: center;

    p {
        margin-left: 10px;
    }

    button {
        width: 200px;
        position: relative;
        overflow: hidden;
        padding: 10px 15px;
        border-radius: 5px;
        color: var(--white-color);
        font-family: Stem-Medium;
        font-size: 1rem;
        line-height: 1.125rem;
        border: none;
        color: var(--black);

        input {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0;
            z-index: 1;
        }
    }
}

.admin-h2-title {
    margin: 20px 0px 10px 0px;
    font-family: Stem-Medium;
    color: var(--other-black);
}

.error-text {
    color: red;
    font-size: 0.875rem;
    margin-top: 5px;
}

.c-red {
    color: red !important;
}

.add-inputs {
    h1 {
        margin: 20px 0px;
        font-size: 24px;
    }
}
</style>
